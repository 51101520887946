body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  direction: ltr;
}

.glass {
  width: 85%;
  margin: 0 auto;

  /* Adjust the alpha value to control the transparency */
  /* backdrop-filter: blur(10px); */
  /* Adjust the blur radius as desired */
  /* -webkit-backdrop-filter: blur(10px); */
  /* Safari support */
  color: #fff;
  /* Text color */
  padding: 16px;
  font-size: 1.5em;
  /* margin-top: -100px; */
  /* Add padding to give some space around the text */
}

.App {
  background-position: center;
  background-size: cover;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
  /* background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32)); */
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
}

.react-player {
  width: 100%;
  position: absolute;
  border-radius: 50px;
}

.footer-logo {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  height: 30px;
  width: 140px;
  padding: 8px;
  z-index: 4;
  border-radius: 40px;
  background-color: black;
  /* background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32)); */
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
  pointer-events: none;
  margin-bottom: -6px;
}

.footer-logo::before,
.footer-logo::after {
  width: 70px;
  height: 35px;
  position: absolute;
  left: 118px;
  bottom: -6px;
  rotate: 45deg;
  background-color: black;
  /* background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32)); */
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
  content: "";
  border-radius: 9px;
}

.footer-logo::after {
  left: -30.5px;
  background-color: black;
  /* background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32)); */
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
  rotate: -45deg;
}

#powered-by-vidquiz {

  bottom: 1.5em;
  left: 1.5em;
  position: absolute;
  z-index: 10;
}



.smalllogo {
  z-index: 4;
    /* width: 120px; */
    max-height: auto;
    position: absolute;
    max-width: 80px;
}

.border-page {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  outline-offset: -10px;
}

.border-img {
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  top: 0;

  left: 50%;
  transform: translateX(-50%);
  position: fixed;
}

.register {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .border-img {
    max-width: 510px;
  }

  .App {
    background: url(https://res.cloudinary.com/shulgirit/image/upload/v1712474189/wiply/vidquiz/walla/DALL_E_2024-04-07_10.05.26_-_Create_an_alternative_dynamic_and_exciting_desktop_background_suitable_for_a_soccer_quiz_game_about_the_Euro_tournament_without_any_text_or_elements_1_slbpen.jpg);
    background-size: cover;
    background-position: center center;
  }

  .video-player {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    height: calc(100vh - 70px);
    z-index: 1;
  }

  .player-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: calc(100vh - 70px);
    background: transparent;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 30px;
    background-color: rgb(32, 32, 32);
  background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
    display: flex;
    background-color: white;

    align-items: center;
    justify-content: center;
  }

  .react-player {
    max-width: 500px;
    height: calc(100vh - 30px);
    /* Adjust the width as needed for desktop screens */
    /* Adjust the height based on the aspect ratio */
  }

  .footer-logo {
    display: none;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid black;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .lineBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: black;
    /* background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32)); */
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
    z-index: 3;
    overflow: none;
  }

  .video-player {
    position: fixed;
    top: 15px;
    left: 15px;
    bottom: 15px;
    right: 15px;
    width: calc(100vw - 30px);
    height: calc(100vh - 30px);
    z-index: 1;
  }

  .player-wrapper {
    position: fixed;
    
    width: calc(100vw - 30px);
    height: calc(100vh - 30px);
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
    display: flex;
    background: transparent;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    top: 15px;
    left: 15px;
    bottom: 15px;
    right: 15px;
  }

  .react-player {
    max-height: fit-content;
    height: fit-content;
    /* Adjust the width as needed for desktop screens */
    /* Adjust the height based on the aspect ratio */
  }
}

.circle {
  position: relative; /* New line */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Added box-shadow */

}

.points {
  font-size: 20px;
  color: white;
  font-weight: bold;

}

#score {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  z-index: 10;
  z-index: 200;
  /* box-: 0px 4px 8px rgba(0, 0, 0, 0.7); Added box-shadow */

}

.rotating-border { /* New class */
  position: absolute;
  content: "";
  border: 2px solid black;
  /* border-top-color: white;
  border-left-color: white; */
  border-radius: 50%;
  width: 100%;
  height: 100%;
  /* animation: rotate 10s infinite linear; */
  box-shadow: 0px 4px 8px rgba(365, 365, 365, 0.6); /* Added box-shadow */

}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}